import React from 'react'

import {DiscoverItem} from './discoverItem'

import Style from './style.module.css'

export const DiscoverSection = ({items}) => (
  <div className={Style.container}>
    {items.map((item, index) => (
      <div className={Style.item} key={'discover_' + index}>
        <DiscoverItem {...item} />
      </div>
    ))}
  </div>
)
