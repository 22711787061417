import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import Styles from './slide.module.css'
import {Link} from 'components/link'

const SlideContent = (props) => (
      <div className={Styles.contentWrapper}>
        <div className={Styles.title} dangerouslySetInnerHTML={{__html:props.title}}/>
        <div className={Styles.content} dangerouslySetInnerHTML={{__html:props.content}}/>
        {(props.button && props.button.url) &&
          <Link className={Styles.button} to={props.button.url} target={props.button.target}>
            <button  dangerouslySetInnerHTML={{__html:props.button.title}} />
          </Link>
        }
      </div>
)


export const Slide = (props) =>{
  const imageData = props.bg_image && props.bg_image.localFile && props.bg_image.localFile.childImageSharp.fluid?props.bg_image.localFile.childImageSharp.fluid:null

  return (
    <>
    {imageData?
     <BackgroundImage
      Tag="div"
      className={Styles.background}
      fluid={imageData}
      fadeIn="soft"
      loading="eager"
     >
       <div className={Styles.overlay} />
     <SlideContent {...props}/>
     </BackgroundImage>:
     <SlideContent {...props}/>
      }
    </>
    
  )
}
