import React from 'react';
import {withAllTopics} from './hooks/withalltopics'
import {SmallTopicCard} from './cards/small'
import Styles from './topicsfeed.module.css'

export const TopicsFeed = (props) => {
  const topics = withAllTopics().slice(0,5)
  return (
    <div className={Styles.grid}>
    {topics && topics.length>0 && topics.map(topic => (
      <div className={Styles.item} key={topic.id}>
        <SmallTopicCard {...topic}/>
      </div>
    ))}
    </div>
  )
}
