import React from 'react'
import {ContentCardWithMeta} from 'components/cards/contentCardWithMeta'
import {withAllNews} from 'components/news/hooks/withallnews'
import {withSlider} from 'components/slider'

const NewsCard = React.forwardRef((props, ref) => (
  <div style={{width: 328}}>
    <ContentCardWithMeta {...props} ref={ref}/>
  </div>
))

export const NewsSlider = (props) => {
  const allNews  = withAllNews()
  const Slider = withSlider(NewsCard, allNews, 16)

  return(
    <div>
      <Slider/>
    </div>
  )
}
