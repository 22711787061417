import React, { useState, useRef } from "react"
import Img from "gatsby-image/withIEPolyfill"
import BackgroundImage from "gatsby-background-image"
import ReactPlayer from "react-player"
import MediaQuery from "react-responsive"
import { Button } from "components/buttons"

import Styles from "./style.module.css"
import { ReactComponent as YoutubePlay } from "images/icons/youtubePlay.svg"

export const MediaAndCard = ({ title, content, video, button, background }) => {
	const image = require("./assets/cover2.png")
	const [playing, setPlaying] = useState(false)
	const imageRef = useRef()
	const [videoHeight, setVideoHeight] = useState(0)
	const playVideo = () => {
		const imageHeight = imageRef.current.clientHeight
		setVideoHeight(imageHeight + "px")
		setPlaying(true)
	}
	return (
		<div className={Styles.container}>
			<MediaQuery query="(min-width: 1281px)">
				<div className={Styles.image}>
					{playing ? (
						<ReactPlayer
							url={video}
							playing={playing}
							width="100%"
							height={videoHeight}
							controls={false}
							loop={true}
							config={{
								youtube: {
									playerVars: { showinfo: 0 },
								},
							}}
						/>
					) : (
						<img ref={imageRef} src={image} onClick={playVideo} />
					)}
					{!playing && false && (
						<YoutubePlay className={Styles.icon} onClick={playVideo} />
					)}
				</div>
				<BackgroundImage
					tag="div"
					fluid={background.fluid}
					className={Styles.content}
					style={{ backgroundSize: "150px", backgroundPosition: "top right" }}
				>
					<h2
						dangerouslySetInnerHTML={{ __html: title }}
						className={Styles.title}
					/>
					<p
						dangerouslySetInnerHTML={{ __html: content }}
						className={Styles.description}
					/>
					<Button
						className="width-full uppercase"
						to={button.url}
						dangerouslySetInnerHTML={{ __html: button.title }}
					/>
				</BackgroundImage>
			</MediaQuery>
			<MediaQuery query="(max-width: 1280px)">
				<BackgroundImage
					tag="div"
					fluid={background.fluid}
					className={Styles.content}
					style={{ backgroundSize: "150px", backgroundPosition: "top right" }}
				>
					<h2
						dangerouslySetInnerHTML={{ __html: title }}
						className={Styles.title}
					/>
					<p
						dangerouslySetInnerHTML={{ __html: content }}
						className={Styles.description}
					/>
					<Button to={button.url}>{button.title}</Button>
				</BackgroundImage>
				<div className={Styles.image}>
					{playing ? (
						<ReactPlayer
							url={video}
							playing={playing}
							width="100%"
							height={videoHeight}
							controls={false}
							loop={true}
							config={{
								youtube: {
									playerVars: { showinfo: 0 },
								},
							}}
						/>
					) : (
						<img ref={imageRef} src={image} onClick={playVideo} />
					)}
					{!playing && <YoutubePlay className={Styles.icon} />}
				</div>
			</MediaQuery>
		</div>
	)
}
