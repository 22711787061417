import React, { Component } from 'react'
import {MediaQuery} from 'react-responsive'

import {ReadMoreButton} from 'components/buttons'

import Img from 'gatsby-image'

import DoubleCollabStyles from './doubleCollab.module.css'

export const DoubleCollaborationWidget = ({projectRight, projectLeft}) => (
  <div>
    <MediaQuery query="(min-width: 1425px)">
      <div className={DoubleCollabStyles.container}>
        <div className={DoubleCollabStyles.elementRight}>
          <div className={DoubleCollabStyles.elementContent}>
            <h3 dangerouslySetInnerHTML={{__html: projectRight.title}}/>
            <p dangerouslySetInnerHTML={{__html: projectRight.excerpt}} />
            <ReadMoreButton
              link={{url: projectRight.path}}
              fontColor='black'
              symbolColor='#AAC939'
            />
          </div>
            <Img fixed={projectRight.featured_media.localFile.childImageSharp.fixed} />
        </div>  
        <div className={DoubleCollabStyles.elementLeft}>
          <Img fixed={projectLeft.featured_media.localFile.childImageSharp.fixed} />
          <div className={DoubleCollabStyles.elementContent}>
          <h3 dangerouslySetInnerHTML={{__html: projectLeft.title}}/>
            <p dangerouslySetInnerHTML={{__html: projectLeft.excerpt}} />
            <ReadMoreButton
              link={{url: projectLeft.path}}
              fontColor='black'
              symbolColor='#AAC939'
            />
          </div>
        </div>
      </div>
    </MediaQuery>
    <MediaQuery query="(max-width: 1424px)">
    <div className={DoubleCollabStyles.mobileContainer}>
        <div className={DoubleCollabStyles.elementImage}>
          <Img fluid={projectRight.featured_media.localFile.childImageSharp.fluid} />
        </div>
        <div className={DoubleCollabStyles.mobileContent}>
          <h3 dangerouslySetInnerHTML={{__html: projectRight.title}}/>
          <p dangerouslySetInnerHTML={{__html: projectRight.excerpt}} />
          <ReadMoreButton
            link={{url: projectRight.path}}
            fontColor='black'
            symbolColor='#AAC939'
          />
        </div>
      </div>
      <div className={DoubleCollabStyles.mobileContainer}>
        <div className={DoubleCollabStyles.elementImage}>
          <Img fluid={projectLeft.featured_media.localFile.childImageSharp.fluid} />
        </div>
        <div className={DoubleCollabStyles.mobileContent}>
          <h3 dangerouslySetInnerHTML={{__html: projectLeft.title}}/>
          <p dangerouslySetInnerHTML={{__html: projectLeft.excerpt}} />
          <ReadMoreButton
            link={{url: projectLeft.path}}
            fontColor='black'
            symbolColor='#AAC939'
          />
        </div>
      </div>
    </MediaQuery>
  </div>
)
