import React, {useState} from 'react';
import BackgroundImage from 'gatsby-background-image'
import { Link } from "components/link"
import {sliceExcerpt} from 'helpers'

import Styles from './discoverItem.module.css'

export const DiscoverItem = ({title, description, color, background_image, url}) => {
  const [hover, setHover] = useState(false);
  const img = background_image && background_image.localFile && background_image.localFile.childImageSharp.fixed

  let descriptionTrimmed = sliceExcerpt(description, 140)
  return (
    <BackgroundImage
      tag="div"
      fixed={img}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Link to={url}>
        <div className={Styles.container} style={{backgroundColor: color+(hover ? 'FF' :'77')}}>
          <h2 className={Styles.title} dangerouslySetInnerHTML={{__html: title}} />
          <p className={Styles.description} dangerouslySetInnerHTML={{__html: descriptionTrimmed}} />
        </div>
      </Link>
    </BackgroundImage>
  )
}
