import React from 'react'
import Carousel from './carousel'
import Section from 'components/section'
import {NewsSlider} from './slider/news-slider'
import {DoubleCollaborationWidget} from 'components/collaboration/doubleCollab.js'
import TwitterTimeline from 'components/twitterTimeLine'
import Styles from './frontpage.module.css'
import {TopicsFeed} from 'components/topics/homefeed'
import {FactSlider} from 'components/facts/slider'
import {BgPattern} from 'components/backgrounds'
import BgStyles from 'components/backgrounds/patterns.module.css'
import {MediaAndCard} from './MediaAndCard'
import {DiscoverSection} from './discoverSection'
export default (props) => {
 
  return (
    <div>
      <div className="mb-16">
        <Carousel slides={props.carousel}/>
      </div>

      <Section
        ContentClassName={Styles.content_news}

        title="Latest news"

        button={{
          url: '/news',
          target: '',
          title :'all news'
        }}
      >
        <BgPattern className={`${BgStyles.pattern1} ${BgStyles.topRight}`}/>
        <NewsSlider/>
      </Section>
      <Section>
        <MediaAndCard
          title={props.acf.embracing_nature.title}
          content={props.acf.embracing_nature.content}
          video={props.acf.embracing_nature.video}
          button={props.acf.embracing_nature.button}
          background={props.embracingNatureBackground}
        />
      </Section>
      <Section
        title="Key Topics"
      >
        <TopicsFeed/>
      </Section>
      
      {props.didYouKnowSection && props.didYouKnowSection.facts && props.didYouKnowSection.facts.length && 
      <Section
        title={props.didYouKnowSection.title}
      >
        <BgPattern className={`${BgStyles.pattern2} ${BgStyles.topRight}`}/>
        <FactSlider facts={props.didYouKnowSection.facts} />
      </Section>
      }
      <Section
        title={props.double_collaboration_widget.title}
        button={props.double_collaboration_widget.button}
      >

        <BgPattern className={`${BgStyles.pattern3} ${Styles.dcwPattern} `}/>
        <DoubleCollaborationWidget 
          projectRight={props.double_collaboration_widget.project_right}
          projectLeft={props.double_collaboration_widget.project_left}
        />
      
      </Section>
      {props.twitter && 
        <Section title={props.twitter.title}>
          <TwitterTimeline
            numberOfTweets={5}
          />
        </Section>
      }
      {props.discover_section && props.discover_section.items && props.discover_section.items.length > 0 && 
      <Section title={props.discover_section.title} maxWidth={1650}>
        <BgPattern className={`${BgStyles.pattern3} ${Styles.discoverPattern} `}/>
        <DiscoverSection items={props.discover_section.items} />
       </Section>
      }
    </div>
  )
}
