import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'


export const withAllTopics = () => {
  const {topics} = useStaticQuery(graphql `
    query AllTopics{
      topics:allWordpressWpTopics(sort:{fields:menu_order}){
        nodes {
          id
          title
          path
          type
          excerpt
          wordpress_id
          color
          icon {
            localFile{
              childImageSharp {
                fixed (width:70, height:70){
                  ...GatsbyImageSharpFixed
                }
                small: fixed(width:50, height:50){
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 369, maxHeight: 270){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
}
`)
  return topics.nodes
}
