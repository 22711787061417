import React from 'react'
import Styles from './smallcard.module.css'
import Img from 'gatsby-image'
import {ReadMoreButton} from 'components/buttons'
import MediaQuery from 'react-responsive'
import {navigate} from 'gatsby'

export const SmallTopicCard = (props) => {
  const [hover, setHover] = React.useState(false)
  return (
    <div
      className={Styles.card}

      onMouseEnter={()=>setHover(true)}
      onMouseLeave={()=>setHover(false)}

    >
      <div className={Styles.header}  style={{backgroundColor:props.color}}>
        {props.icon && props.icon.localFile &&
         <Img fixed={props.icon.localFile.childImageSharp.small}/>
        }

        <p className={Styles.title} dangerouslySetInnerHTML={{__html:props.title}}/>

        {hover &&
        <div className={Styles.content}>
          <ReadMoreButton
            fontColor='#fff'
            symbolColor="#fff"
            textClass='text-xl'
            fontColor='#fff'
            symbolColor="#fff"
            showSymbol={false}
            className="mt-5"
            link={{
              url: props.path,
              title: props.title
            }}
          />
        </div>
        }
      </div>
      {!hover &&
       <div className={Styles.imageWrapper}>
	   { props?.featured_media?.localFile?.childImageSharp?.fluid &&
		 <Img fluid={props.featured_media.localFile.childImageSharp.fluid} style={{height:"100%"}}/>
	   }
       </div>
      }
    </div>
  )
}
