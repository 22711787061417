import React, { useEffect, useReducer, useRef, useState } from "react"
import { Slide } from "./slide"
import Style from "./carousel.module.css"
import Stepper from "./stepper"

import ReactCSSTransitionGroup from "react-addons-css-transition-group"

import "./animation.css"

const changeSlide = (state, action) => {
	switch (action.type) {
		case "next":
			if (state.currentIndex + 1 < state.allSlides.length) {
				return {
					...state,
					currentIndex: state.currentIndex + 1,
					animation: "right",
				}
			} else {
				return {
					...state,
					currentIndex: 0,
					animation: "right",
				}
			}

		case "prev":
			if (state.currentIndex > 0) {
				return {
					...state,
					currentIndex: state.currentIndex - 1,
					animation: "left",
				}
			} else {
				return {
					...state,
					currentIndex: state.allSlides.length,
					animation: "left",
				}
			}
		default:
			throw new Error("unknown action")
	}
}

export default props => {
	const [autoplay, setAutoplay] = useState(true)
	const baseId = "FrontPageCarousel"

	const initialState = {
		allSlides: [...props.slides],
		currentIndex: 0,
	}
	const [state, dispatch] = useReducer(changeSlide, initialState)
	const [animating, setAnimating] = useState(false)
	const nextSlide = () => {
		if (animating) return
		dispatch({ type: "next" })
		setAnimating(true)
		setTimeout(() => setAnimating(false), 1000)
	}
	const prevSlide = () => {
		if (animating) return
		dispatch({ type: "prev" })
		setAnimating(true)
		setTimeout(() => setAnimating(false), 1000)
	}

	useEffect(() => {
		const interval = setInterval(() => {
			if (autoplay) {
				nextSlide()
			}
		}, 5000)
		return () => clearInterval(interval)
	}, [nextSlide, autoplay])
	return (
		<div
			id={baseId}
			className={Style.carousel}
			onMouseEnter={() => setAutoplay(false)}
			onMouseLeave={() => setAutoplay(true)}
		>
			<div className={Style.wrapper}>
				{state.allSlides.length > 1 && (
					<Stepper
						baseId={baseId}
						hasNext={state.currentIndex + 1 < state.allSlides.length}
						hasPrev={state.currentIndex > 0}
						current={state.currentIndex + 1}
						total={state.allSlides.length}
						onNext={nextSlide}
						onPrev={prevSlide}
					/>
				)}
				<ReactCSSTransitionGroup
					transitionName={"slide-" + state.animation}
					transitionEnterTimeout={1000}
					transitionLeaveTimeout={1000}
				>
					<Slide
						{...state.allSlides[state.currentIndex]}
						key={state.currentIndex}
					/>
				</ReactCSSTransitionGroup>
			</div>
		</div>
	)
}
